<template>
  <div class="sld_contract_detail">
    <!-- <MemberTitle :memberTitle="L['查看合同']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L['查看合同'] }}</h3>
      <div class="main" v-if="contract.info.contractUrl">
        <object :data="contract.info.contractUrl" type="application/pdf" width="100%" height="100%">
          <iframe :src="contract.info.contractUrl" width="100%" height="100%" style="border: none;">
            This browser does not support PDFs. Please download the PDF to view it:
            <a href="JavaScript：void(0)">Download PDF</a>
          </iframe>
        </object>
      </div>
      <SldCommonEmpty v-show="!contract.info.contractUrl" :tip="L['暂无合同详情']" totalWidth="934px" />
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import SldCommonEmpty from '@/components/SldCommonEmpty';
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus';
export default {
  name: "FinaceContractDetail",
  components: {
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const route = useRoute();
    const id = ref(route.query.id);
    const ids = ref(route.query.ids);
    const contract = reactive({ info: {} });

    onMounted(()=>{
      getDetail();
    })

    const getDetail =()=> {
      proxy.$get('v3/business/front/contract/detail', {
        contractId: id.value,
        contractProductIds: ids.value
      }).then(res=>{
        if (res.state == 200) {
          contract.info = res.data
        } else {
          ElMessage.error(res.msg);
        }
      })
    };

    return {
      L,
      id,
      ids,
      contract,
      getDetail,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/contract/detail.scss";
</style>